/** @format */

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background-color: #000000;
}

p,
a,
h1,
h2,
h3,
label {
  color: white;
  font-family: "Poppins", sans-serif;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  column-gap: 25px;
}

.col-md-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-md-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}

.col-md-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-md-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0px 15px;
}

.col-md-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  padding: 0px 15px;
}
.commonTitle {
  color: #dec263;
  font-family: "Poppins", Sans-serif;
  font-weight: 700;
  font-size: 32px;
  font-style: italic;
}
.border {
  border: 1px solid white;
}
